import { createFromIconfontCN } from '@ant-design/icons-vue';

/**
 * 更新图标分3步:
 * 1. 下载最新压缩包
 * 2. 替换public/icon-font文件夹中的iconfont.js文件
 * 3. 参数替换成当前时间：?t=xxxxx
 */
export default defineNuxtPlugin((nuxtApp) => {
  const IconFont = createFromIconfontCN({
    scriptUrl: '/icon-font/iconfont.js' + '?t=20241011',
  });
  nuxtApp.vueApp.component('IconFont', IconFont);
});
