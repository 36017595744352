import { isEmpty, isNil, isString } from 'lodash-es';
import { Layouts } from '~/stores/layout';

export default defineNuxtRouteMiddleware(async (to) => {
  // url变更，服务器做重定向
  if (to.path.includes('/artimind-ai')) {
    return navigateTo({
      path: '/aimake',
      query: {
        ...to.query,
      },
    });
  }

  if ((to.meta?.auth || matchAuthRoute(to.path)) && isNil(Cookie.get('utoken'))) {
    return navigateTo({
      path: '/',
      query: {
        isNeedAuth: 'true',
        redirect: to.fullPath,
      },
    });
  }
  if (import.meta.client && to.query?.isNeedAuth === 'true') {
    const { $client } = useNuxtApp();
    const query = { ...to.query };
    changeUrlStatic();
    if (isNil(Cookie.get('utoken'))) {
      $client.openModal(() => {
        isString(query.redirect) ? window.location.replace(query.redirect) : location.reload();
      });
    } else {
      // 兼容 XCS
      window.location.replace(query.redirect as any);
    }
  }

  // 应用布局
  if (matchUseCommonLayoutRoute(to.path)) {
    setPageLayout(Layouts.Common);
  } else {
    setPageLayout(Layouts.Default);
  }

  // 访问未定义路由时
  if (!to.matched.length) {
    return abortNavigation();
  }
});

// 全局路由跳转中间件
// navigateTo方法控制路由跳转
// abortNavigation方法课阻止路由跳转
const changeUrlStatic = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('isNeedAuth');
  params.delete('redirect');

  const query = params.toString();
  const path = window.location.pathname;
  const url = !isEmpty(path) ? `${path}?${query}` : `?${query}`;
  window.history.replaceState(null, 'null', url);
};

const useCommonLayoutRoutes = ['/order/', '/order/detail/', '/point-history', '/xart-usage'];
function matchUseCommonLayoutRoute(path: string) {
  return useCommonLayoutRoutes.some((r) => path.includes(r));
}

// 客户端需要登录的路由
const authRoutes = ['/creator/center', '/share/project', '/share/bundle', '/share/designs'];
function matchAuthRoute(path: string) {
  return authRoutes.some((r) => path.startsWith(r));
}
