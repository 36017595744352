<template>
  <a-extract-style>
    <a-config-provider :theme="antThemeConfig">
      <NuxtLayout>
        <NuxtLoadingIndicator :color="'#FF0035'" />
        <NuxtPage :keepalive="keepAlive" :max="max" />
      </NuxtLayout>
    </a-config-provider>
  </a-extract-style>
</template>
<script setup lang="ts">
import { antThemeConfig } from '@/config/ant-theme';
import { keepAlive, max } from '@/config/keep-alive';
import { isInIframe } from '@/stores/types';
// 提前加载设计文件字体
import { useFont } from '@/utils/useFont';
import { v4 } from 'uuid';
import { useRoute } from 'vue-router';
import { useUserInfo } from '~/stores/user';

useFont();

const route = useRoute();
// passport内嵌站点发生路由的变化，需要上报一个路由改变的消息。
watch(
  () => route,
  () => {
    routeChange();
  },
  {
    deep: true,
  },
);
const routeChange = () => {
  window.parent.postMessage(
    {
      action: 'route-change',
    },
    '*',
  );
};

// 监听xcs-app的reload-page事件，用于刷新页面
const ACTION: any = {
  ['reload-page'](e: MessageEvent) {
    if (e.data.from === 'xcs-app' && isInIframe()) {
      window.location.reload();
    }
  },
};

const listenerReloadPage = (e: MessageEvent) => {
  const { action } = e.data;
  ACTION[action] && ACTION[action](e);
};

// 初始化时,利用 useCookie 方法写入一个cid字段
const cid = useCookie('cid');
if (!cid.value) {
  cid.value = v4();
}

onMounted(() => {
  window.addEventListener('message', listenerReloadPage);
});
onUnmounted(() => {
  window.removeEventListener('message', listenerReloadPage);
});

/**
 * @description: 用于移动端调试   勿删
 */

// onMounted(() => {
//   setTimeout(async () => {
//     const Vconsole: any = await import('vconsole');
//     console.log(Vconsole);

//     new Vconsole.default();
//   }, 200);
// });

// 在根组件中捕获错误，防止应用崩溃，返回 false
onErrorCaptured((err, instance, info) => {
  console.error(err);
  console.log('instance :>> ', instance);
  console.log('info :>> ', info);
  return false;
});

// 初始化用户信息
const userInfoStore = useUserInfo();

const isInit = useState('GLOBAL_USER', () => false);
if (getUToken() && !isInit.value) {
  // 首页加了缓存需要特殊处理
  const isHome = ['/', '/explore/featured'].includes(route.path);
  if (!isHome) {
    await userInfoStore.init();
    isInit.value = true;
  } else {
    onMounted(async () => {
      await userInfoStore.init();
      isInit.value = true;
    });
  }
}
</script>
